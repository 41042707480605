import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'
import Img from 'gatsby-image'
import ctaVideo from '../videos/cta.mp4'
import Vendor from '../components/vendor/vendor'

class Merchandise extends React.Component {
  render () {
    return (
      <Layout>
        <SEO
          title="Merchandise"
          bodyClass="body--light"
          description="Official film merchandise"
        />
        <article>
          <div className="c-masthead">
            <div className="container">
              <div className="row">
                <div className="c-masthead__title-container">
                  <h1 className="c-masthead__title">Film Merchandise</h1>
                </div>
              </div>
            </div>
            <div className="c-masthead__image-container">
              <Img
                fluid={
                  this.props.data.title.childImageSharp.fluid
                }
                alt=""
                className="c-masthead__image"
              />
            </div>
          </div>
          <div className="c-product-listing">
            <div className="container">
              <div className="row">
                <div className="c-product-listing__item">
                  <Img
                    fluid={
                      this.props.data.soundtrackDigital.childImageSharp.fluid
                    }
                    className="c-product-listing__item-media"
                    alt="Digital Soundtrack"
                  />
                  <h2 className="c-product-listing__item-title">
                    Digital Soundtrack
                  </h2>
                  <p className="c-product-listing__item-summary">
                    Buy the incredible soundtrack of The Edge, beautifully crafted by Felix White from The Maccabees. Available from various online retailers and music streaming platforms.
                  </p>
                  <div className="c-product-listing__item-price-container">
                    <span className="c-product-listing__item-price">From £7.99</span>
                    <a href="http://hyperurl.co/TheEdgeFW" className="c-product-listing__item-buy c-btn c-btn--primary" target="_blank">Buy Now</a>
                  </div>
                </div>
                <div className="c-product-listing__item">
                  <Img
                    fluid={
                      this.props.data.soundtrackVinyl.childImageSharp.fluid
                    }
                    className="c-product-listing__item-media"
                    alt="Vinyl Soundtrack"
                  />
                  <h2 className="c-product-listing__item-title">
                    Vinyl Soundtrack
                  </h2>
                  <p className="c-product-listing__item-summary">
                    Buy a signed vinyl of the incredible soundtrack of The Edge, beautifully crafted by Felix White from The Maccabees. Poster package option included!
                  </p>
                  <div className="c-product-listing__item-price-container">
                    <span className="c-product-listing__item-price">From £18.99</span>
                    <a href="https://theedgefilm.tmstor.es" className="c-product-listing__item-buy c-btn c-btn--primary" target="_blank">Buy Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Vendor className="c-vendor--inpage" />
          <div className="c-cta c-cta--nomargin-bottom">
            <video
              src={ctaVideo}
              className="c-cta__video"
              autoPlay muted playsInline loop>
            </video>
            <div className="container">
              <div className="row">
                <div className="c-cta__link-container">
                  <Link className="c-cta__link" to="/follow-us">Follow Us</Link>
                </div>
              </div>
            </div>
          </div>
        </article>
      </Layout>
    )
  }
}

export const fluidImage = graphql`
  fragment productImage on File {
    childImageSharp {
      fluid(maxWidth: 600) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    title: file(relativePath: { eq: "merchandise/title.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    soundtrackDigital: file(relativePath: { eq: "merchandise/soundtrack.jpg" }) {
      ...productImage
    }
    soundtrackVinyl: file(relativePath: { eq: "merchandise/soundtrack.png" }) {
      ...productImage
    }
  }
`

export default Merchandise
